import React from 'react';
import { Drawer, Button } from 'freemium-ui';
import moment from 'moment';
import {
  Form,
  FormActions,
  Header,
  BodyContent,
  ModalContent
} from './styled/MigrationExportGuideModal';
import { exportModalInstructions, dateFormat } from '../constants/constant';

const MigrationExportGuideModal = ({
  showLearnMoreModal,
  setShowLearnMoreModal,
  setShowExportModal,
  deprecation
}) => {
  const formatDescriptionText = descriptionText => {
    return descriptionText
      ? descriptionText.replace(
          new RegExp(/end_date/, 'g'),
          moment(deprecation['end_date']).format(dateFormat)
        )
      : descriptionText;
  };
  return (
    <Drawer
      isOpen={showLearnMoreModal}
      onDismiss={() => setShowLearnMoreModal(false)}
      isFullHeight
      size="small"
      clickOutToClose={false}
      placement="right"
    >
      <ModalContent>
        <Header>
          <div className="content-title">What happens next?</div>
          <div className="description">
            Find answers to common questions about your next steps.
          </div>
        </Header>

        <Form>
          {exportModalInstructions.map(content => (
            <BodyContent>
              <div className="content-title">{content.title}</div>
              <div className="description" dangerouslySetInnerHTML={{ __html: formatDescriptionText(content.description) }} />
            </BodyContent>
          ))}

          <FormActions>
            <Button
              size="normal"
              inline
              htmlType="button"
              type="secondary"
              onClick={() => setShowLearnMoreModal(false)}
            >
              Close
            </Button>
            <Button
              size="normal"
              inline
              type="primary"
              htmlType="button"
              onClick={() => {
                setShowLearnMoreModal(false);
                setShowExportModal(true);
              }}
            >
              Export
            </Button>
          </FormActions>
        </Form>
      </ModalContent>
    </Drawer>
  );
};

export default MigrationExportGuideModal;
