import React from 'react';
import { Modal } from 'freemium-ui';
import {
  ModalFooter,
  ModalBody,
  ModalHeader
} from './styled/MigrationExportGuideModal';

const MigrationBannerExportModal = ({ setShowExportModal, initiateExport }) => {
  return (
    <Modal isOpen={true}>
      <ModalHeader>
        <div>Permission Required</div>
      </ModalHeader>
      <ModalBody>
        <h3 className="body-title">
          This action can only be undertaken by account admins or account owners
        </h3>
        <p>
          You're about to request a data export. Once requested, you cannot
          submit another export until this one is processed. Your account will
          remain active throughout the export process. Within 48 hours, all
          admins and owners will receive an email with a secure download link,
          which will stay valid for 60 days.
        </p>
        <p>
          For support, please reach out to please reach out to our team at
          support.freshping@freshworks.com. We're here to help you through every
          step of the deprecation process, including data export, migration
          assistance, and answering any specific question you may have.
        </p>
        <p>Are you sure you want to proceed?</p>
      </ModalBody>

      <ModalFooter>
        <button
          onClick={() => setShowExportModal(false)}
          className="secondary-btn"
        >
          Cancel
        </button>
        <button onClick={initiateExport} className="primary-btn">
          Confirm export
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default MigrationBannerExportModal;
